import { mesDataService } from "@/services/mesDataService";

const pmRound = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {

    },
    mutations: {
        LIST_DATA(state, payload) {
           payload ? state.data = payload : []
        },
        
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        
        async create(_, payload) {                        
                const result = await mesDataService.pmRound.create(payload)
                                            
                return result.data           
        },

        async update({ rootState }, payload) {
         
                payload.updatedBy = rootState.authen.user.email
                await mesDataService.pmRound.update(payload.id, payload)
               
                return           
        },

        // async list({ dispatch}) {
        //     try {
        //         const result = await mesDataService.pmRound.list();
                                        
        //         return result.data
        //     } catch (e) {
        //         dispatch('utils/handleHttpError', e, { root: true })
        //     }
        // },

        async destroy({ dispatch, commit }, id) {
            try {                

                await mesDataService.pmRound.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        
        async listByTableId({ dispatch, commit }, id) {
            try {
               
                const result = await mesDataService.pmRound.listByTableId(id);             
                const data = result.data.sort((a, b) => (a.pm_table_item.pm_part.pm_category.id > b.pm_table_item.pm_part.pm_category.id) ? 1 : (b.pm_table_item.pm_part.pm_category.id > a.pm_table_item.pm_part.pm_category.id) ? -1 : 0)
                                      
                commit('LIST_DATA', data)
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async CreateCopy({ dispatch },id){
            try {
                const result = await mesDataService.pmRound.CreateCopy(id);             
                // const data = result.data.sort((a, b) => (a.pm_table_item.pm_part.pm_category.id > b.pm_table_item.pm_part.pm_category.id) ? 1 : (b.pm_table_item.pm_part.pm_category.id > a.pm_table_item.pm_part.pm_category.id) ? -1 : 0)
                                      
                // commit('LIST_DATA', data)
                
                 return result
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                
            }
        }

        
      

    }
}

export { pmRound }