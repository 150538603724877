import { mesDataService } from "@/services/mesDataService";

const pmMr = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {

        async create({ dispatch, rootState }, payload) {

            try {

                payload.updatedBy = rootState.authen.user.email
                const result = await mesDataService.pmMr.create(payload)

                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async getMaxMrno(_, payload) {

            const result = await mesDataService.pmMr.getMaxMrno(payload.startDate, payload.endDate);

            return result.data[0]

        },

        async update({ dispatch, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email

                await mesDataService.pmMr.update( payload)

                dispatch('list')
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async getMrnoCutting({ dispatch }) {
            try {
                const result = await mesDataService.pmMr.getMrnoCutting();
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },
    }
}

export { pmMr }