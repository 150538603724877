import { mesDataService } from "@/services/mesDataService";


const supportMachineMajor = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {

    },
    mutations: {
        LIST_DATA(state, payload) {
            payload.forEach(obj => {
                if (!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        async create({ dispatch, commit }, payload) {
            try {
                const result = await mesDataService.supportMachineMajor.create(payload)
                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded', null, { root: true })


                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit }, payload) {
            try {
                await mesDataService.supportMachineMajor.update(payload.id, payload)
                commit('UPDATE_DATA', payload)
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async get({ commit, dispatch }, payload) {
            try {

                const result = await mesDataService.supportMachineMajor.get(payload.workingDate, payload.projectId)

                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async list({ dispatch, commit }, payload) {
            try {

                const result = await mesDataService.supportMachineMajor.list(payload.startDate, payload.endDate, payload.projectId)
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

       
        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.supportMachineMajor.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

    }

}

export { supportMachineMajor }