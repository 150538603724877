import { mesDataService } from "@/services/mesDataService";

const haulingProductionDash = {
    namespaced: true,
    state: {
    },

    getters: {
    },

    mutations: {

    },

    actions: {

        async haulingProductionPerMonth({ dispatch }, payload) {
            try {

                const result = await mesDataService.dashboard.haulingProductionPerMonth(payload.startDate, payload.endDate, payload.projectId, payload.loadingSpot, payload.dumpSpot);


                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async haulingProductionPerYear({ dispatch }, payload) {
            try {

                const result = await mesDataService.dashboard.haulingProductionPerYear(payload.year, payload.projectId, payload.loadingSpot, payload.dumpSpot);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async haulingTruck({ dispatch }, payload) {
            try {

                const result = await mesDataService.dashboard.haulingTruck(payload.startDate, payload.endDate, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

    }
}

export { haulingProductionDash }
