import { mesDataService } from "@/services/mesDataService";

const excavatorPerformanceDash = {
    namespaced: true,
    state: {
    },

    getters: {
    },

    mutations: {

    },

    actions: {

        async performanceExcavatorLoadingMeterMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorLoadingMeterMonth(payload.startDate, payload.endDate, payload.projectId, payload.energyId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceExcavatorEnergyPerMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorEnergyPerMonth(payload.startDate, payload.endDate, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceExcavatorLoadingVolumeMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorLoadingVolumeMonth(payload.startDate, payload.endDate, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async performanceExcavatorLoadingMeterYear({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorLoadingMeterYear(payload.year, payload.projectId, payload.energyId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceExcavatorEnergyPerYear({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorEnergyPerYear(payload.year, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceExcavatorLoadingVolumeYear({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorLoadingVolumeYear(payload.year, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        }

    }

}


export { excavatorPerformanceDash }
