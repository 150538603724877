import { mesDataService } from "@/services/mesDataService";

const pmMrApproval = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async create({ dispatch, rootState }, payload) {

            try {                
                const dataArray = payload.map(item => {
                    return { ...item, updatedBy: rootState.authen.user.email }
                })

                const result = await mesDataService.pmMrApproval.create(dataArray)
                             
                dispatch('utils/alertAdded', null, { root: true })

                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async bulkUpdate({ dispatch }, payload) {
            try {
                
              
                await mesDataService.pmMrApproval.bulkUpdate(payload)
                              
                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async destroy({ dispatch }, pmAppointmentId) {
            try {
                await mesDataService.pmMrApproval.destroy(pmAppointmentId)
               
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },



        async listActiveApproved({ dispatch }) {
            try {

                const result = await mesDataService.pmMrApproval.listActiveApproved()
               
                return result.data
            } catch (e) {              
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async findIdMrApproved({ dispatch },payload) {
            try {
               
                const result = await mesDataService.pmMrApproval.findIdMrApproved(payload.pmMrId,payload.items)
                return result.data
            } catch (e) {              
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async listActiveAdjustedStock({ dispatch },payload) {
            try {
                const result = await mesDataService.pmMrApproval.listActiveAdjustedStock(payload)
                return result.data
            } catch (e) {              
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

       
    }
}

export { pmMrApproval }