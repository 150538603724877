import { mesDataService } from "@/services/mesDataService";

const machineWorkGroup = {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload.map(obj => {
                if (!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },

        SET_DATA(state, payload) {
            payload.forEach(obj => state.data.push(obj))
        },

      
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.machineId !== payload)
        }

    },
    actions: {
        async create({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { ...item, updatedBy: rootState.authen.user.email }
                })
                const result = await mesDataService.machineWorkGroup.create(dataArray)
                commit('SET_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

      

        async destroy({dispatch, commit}, machineId) {
            try {
                await mesDataService.machineWorkGroup.destroy(machineId)
                commit('DEL_DATA', machineId)
                return 1
            } catch(e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },
        
        async findByMachineId({dispatch, commit}, machineId) {
            try {
                
                const result = await mesDataService.machineWorkGroup.findByMachineId(machineId)
                
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async list({dispatch, commit}) {
            try {
                
                const result = await mesDataService.machineWorkGroup.list()
                
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


    }

}


export { machineWorkGroup }