import { mesDataService } from "@/services/mesDataService";

const loadingSpotDash = {
    namespaced: true,
    state: {
    },

    getters: {
    },

    mutations: {

    },

    actions: { 

        async loadingSpotZone({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.loadingSpotZone(payload.startDate, payload.endDate, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
    }
}

export { loadingSpotDash  }

