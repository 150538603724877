import { mesDataService } from "@/services/mesDataService";

const energyApproval = {
    namespaced: true,
    state: {
        data: [],
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload.forEach(obj => {
                const found = state.data.some(item => item.id === obj.id)
                if (found) {
                    const newData = state.data.map(el => el.id === obj.id ? Object.assign(el, obj) : el)
                    state.data = newData
                } else {
                    state.data.push(obj)
                }
            })
        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        async create({ commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
                const result = await mesDataService.energyApproval.create(payload)
                commit('SET_DATA', result.data)

                return [result.data, null]
            } catch (e) {

                return [null, e]
            }

        },

        async update({ commit }, payload) {
            try {
                const data = await mesDataService.energyApproval.update(payload.id, payload)
                commit('UPDATE_DATA', payload)

                return [data, null]
            } catch (e) {

                return [null, e]
            }



        },

        async listBetweenDate({ commit }, payload) {
            try {

                const result = await mesDataService.energyApproval.listBetweenDate(payload.startDate, payload.endDate, payload.projectId);
                commit('LIST_DATA', result.data)

                return [result.data, null]
            } catch (e) {
                return [null, e]
            }
        },
    }
}

export { energyApproval }