import { mesDataService } from "@/services/mesDataService";
import { arrange } from "@/libs/common.mutations";


const energy = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {   
        
    },
    mutations: {
        SET_DATA(state, payload) {
            arrange(state, payload)
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        }
    },
    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
           
                const result = await mesDataService.energy.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded',null, {root: true})

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email         

            payload.updatedBy = uid;
          
            try {
                await mesDataService.energy.update(payload.id, payload)
                commit('SET_DATA', payload)
                dispatch('utils/alertUpdated',null,{root:true})

                return
            } catch (e) {
                
                dispatch('utils/handleHttpError', e, { root: true })
               
            }             
        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.energy.destroy(id)               
                commit('DEL_DATA', id)   
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {
           
            try {                
                const result = await mesDataService.energy.list();
                commit('SET_DATA', result.data)

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return

        }


    },
}

export {energy}