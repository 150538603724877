import { mesDataService } from "@/services/mesDataService";

const pmDocFile = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
    },
    mutations: {       
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
              
        async listID({ dispatch, commit }, payload) {
            try {
                const result = await mesDataService.pmDocFile.listID(payload);
                commit('LIST_DATA', result.data)                           
                                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async create({ dispatch, rootState }, payload) {
            try {
                const item = {file_name:payload.file_name,
                    type_img:payload.type_img,
                    pmAppointmentId:payload.id,
                    updatedBy:rootState.authen.user.email}
                // payload.updatedBy = rootState.authen.user.email
                const result = await mesDataService.pmDocFile.create(item) 
                // dispatch('utils/alertAdded', null, { root: true })
                        

                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async bulkUpdate({ dispatch }, payload) {
            try {
                await mesDataService.pmDocFile.bulkUpdate(payload)
                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.pmDocFile.destroy(id)
                commit('DEL_DATA', id)
                dispatch('utils/alertUpdated', null, { root: true })


                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },
        async destroyId({ dispatch }, id) {
            try {
                await mesDataService.pmDocFile.destroyId(id)
                dispatch('utils/alertUpdated', null, { root: true })


                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },


    },

    
}


export { pmDocFile }