const arrange = (state, payload) => {
    
    const arr = Array.isArray(payload)
    if (arr) {
        // list 
        state.data = payload
    } else {
        // update                
        const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
        state.data = newData

        //add 
        if (!state.data.some(obj => obj.id === payload.id)) {
            state.data.splice(0, 0, payload)
        }
    }
    
}

export { arrange }