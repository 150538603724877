import { mesDataService } from "@/services/mesDataService";

const machineDaily = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {},
    mutations: {
        LIST_DATA(state, payload) {
            payload.forEach(obj => {
                if (!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        async create({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { ...item, updatedBy: rootState.authen.user.email }
                })
                const result = await mesDataService.machineDaily.create(dataArray)
                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded', null, { root: true })

                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { ...item, updatedBy: rootState.authen.user.email }
                })
                const result = await mesDataService.machineDaily.update(dataArray)
                commit('SET_DATA', result.data)
                dispatch('utils/alertUpdated', null, { root: true })

                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async get({ commit, dispatch }, payload) {
            try {

                const result = await mesDataService.machineDaily.get(payload.workingDate, payload.projectId)
              
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        
    },

}

export { machineDaily }