import { mesDataService } from "@/services/mesDataService";

const projectWork = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {       
    },
    mutations: {
        LIST_DATA(state, payload) {
            state.data = payload
        },

        SET_DATA(state, payload) {                               
           
            payload.forEach(obj => state.data.push(obj))
                     
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.projectId !== payload)
        }
    },
    actions: {
        async create({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { ...item, updatedBy: rootState.authen.user.email }
                })
                const result = await mesDataService.projectWork.create(dataArray)
                commit('SET_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByProjectId({ dispatch }, projectId) {
            try {
                const result = await mesDataService.projectWork.findByProjectId(projectId);
                
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

        },


        async list({ dispatch,commit }) {

            try {

                const result = await mesDataService.projectWork.list();
                
                commit('LIST_DATA', result.data)

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return

        },


        async destroy({ dispatch, commit }, projectId) {
            try {
                await mesDataService.projectWork.destroy(projectId)
                commit('DEL_DATA', projectId)
                return 1
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },


    },
}

export { projectWork }