import { mesDataService } from "@/services/mesDataService";
import { arrange } from "@/libs/common.mutations";


const dumpSpot = {
    namespaced: true,
    state: {
        data: [],
       
    },
    getters: { 
        fullData: (state, getters, rootState) => { 
            const projectData = rootState.project.data;   
              
            const newArrayData = state.data.map(el => {
              const projObj = projectData.find(obj => obj.id === el.projectId)
                         
              return {...el, ...{projectName: projObj?projObj.name||null:null}}
              
            })

            return newArrayData
            
        },

        dataActived: (state) => {
            
            return state.data.filter(obj => obj.active == 1)
        }
    },
    mutations: {
        SET_DATA(state, payload) {
            arrange(state, payload)

           
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
        
    },
    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
           
                const result = await mesDataService.dumpSpot.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded',null, {root: true})

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email         

            payload.updatedBy = uid;
          
            try {
                await mesDataService.dumpSpot.update(payload.id, payload)
                commit('SET_DATA', payload)
                dispatch('utils/alertUpdated',null,{root:true})

                return
            } catch (e) {
                
                dispatch('utils/handleHttpError', e, { root: true })
               
            }             
        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.dumpSpot.destroy(id)               
                commit('DEL_DATA', id)   
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {
           
            try {                
                const result = await mesDataService.dumpSpot.list();
                              
                commit('SET_DATA', result.data)

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        }


    },
}

export {dumpSpot}