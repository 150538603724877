import { mesDataService } from "@/services/mesDataService";

const crawlerDrillDash = {
    namespaced: true,
    state: {
        data: [],
    },

    getters: {
    },

    mutations: {
        SET_DATA(state, payload) {
            payload ? state.data = payload : []
         },
    },

    actions: {

        async performanceCrawlerDrill({dispatch, commit}, payload) {
            try {
               
                const result = await mesDataService.dashboard.performanceCrawlerDrill(payload.startDate, payload.endDate);
                commit('SET_DATA', result.data)
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        }      

    }
}

export { crawlerDrillDash }

