import { mesDataService } from "@/services/mesDataService";

const pmTableMachine = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
    },
    mutations: {       
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
       
        async countTableUsage({ dispatch}) {
            try {
                const result = await mesDataService.pmTableMachine.countTableUsage();
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listMaxId({ dispatch, commit }) {
            try {
                const result = await mesDataService.pmTableMachine.listMaxId();
                commit('LIST_DATA', result.data)                           
                                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async create({ dispatch, rootState }, payload) {

            try {
                payload.updatedBy = rootState.authen.user.email
                const result = await mesDataService.pmTableMachine.create(payload) 
                dispatch('listMaxId')       
                dispatch('utils/alertAdded', null, { root: true })
                        

                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email

                await mesDataService.pmTableMachine.update(payload.id, payload)
                dispatch('listMaxId')
                
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.pmTableMachine.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },


    },

    
}


export { pmTableMachine }