import { mesDataService } from "@/services/mesDataService";


const dashboard = {

    namespaced: true,
    state: { 
        bcmVolume: false
    },

    getters: {       
    },

    mutations: {
        SET_BCM_VOLUME(state, payload) {
            state.bcmVolume = payload
        }   
    },

    actions: {     
        
        // HAULING ------------------------------------------------------------------------------------------

       

        async getHaulingTimesTonsOperator({ dispatch }, payload) {
            try {
               
                const result = await mesDataService.dashboard.getHaulingTimesTonsOperator(payload.year, payload.projectId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },       

        async getBubbleOperator({ dispatch }, payload) {
            try {               
                const result = await mesDataService.dashboard.getBubbleOperator(payload.year, payload.projectId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

              
        // performanceTruckDash----------------------------------------------------------------------------------

        async performanceTruckHaulingMeterMonth({dispatch}, payload) {
            try {                           
              
                const result = await mesDataService.dashboard.performanceTruckHaulingMeterMonth(payload.startDate,payload.endDate,payload.projectId,payload.energyId);
               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceTruckEnergyPerMonth({dispatch}, payload) {
            try {               
                const result = await mesDataService.dashboard.performanceTruckEnergyPerMonth(payload.startDate,payload.endDate, payload.projectId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceTruckHaulingVolumeMonth({dispatch}, payload) {
            try {               
                const result = await mesDataService.dashboard.performanceTruckHaulingVolumeMonth(payload.startDate,payload.endDate, payload.projectId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceTruckHaulingMeterYear({dispatch}, payload) {
            try {               
                const result = await mesDataService.dashboard.performanceTruckHaulingMeterYear(payload.year, payload.projectId, payload.energyId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceTruckEnergyPerYear({dispatch}, payload) {
            try {               
                const result = await mesDataService.dashboard.performanceTruckEnergyPerYear(payload.year, payload.projectId, payload.groupBy);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceTruckHaulingVolumeYear({dispatch}, payload) {
            try {               
                const result = await mesDataService.dashboard.performanceTruckHaulingVolumeYear(payload.year, payload.projectId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        // -----------------------------------------------------------------------------------------------------

        // async yearGroup({dispatch}, payload) {
        //     try {
        //         const result = await mesDataService.dashboard.yearGroup(payload.projectId);
                
        //         return result.data

        //     } catch (e) {
        //         dispatch('utils/handleHttpError', e, { root: true })
        //     }
        // },


        // ------Preformance Truck Period----------------------------------------

        async performancePeriodHaulingMeterMonth({dispatch}, payload) {
            try {
        
                const result = await mesDataService.dashboard.performancePeriodHaulingMeterMonth(payload.startDate, payload.endDate, payload.projectId,payload.energyId,payload.truckId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performancePeriodEnergyPerMonth({dispatch}, payload) {
            try {
               
                const result = await mesDataService.dashboard.performancePeriodEnergyPerMonth(payload.startDate, payload.endDate, payload.projectId,payload.truckId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performancePeriodHaulingVolumeMonth({dispatch}, payload) {
            try {
               
                const result = await mesDataService.dashboard.performancePeriodHaulingVolumeMonth(payload.startDate, payload.endDate, payload.projectId,payload.energyId,payload.truckId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performancePeriodHaulingMeterYear({dispatch}, payload) {
            try {
               
                const result = await mesDataService.dashboard.performancePeriodHaulingMeterYear(payload.year, payload.projectId, payload.energyId, payload.truckId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performancePeriodEnergyPerYear({dispatch}, payload) {
            try {
               
                const result = await mesDataService.dashboard.performancePeriodEnergyPerYear(payload.year, payload.projectId ,payload.truckId);
              
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performancePeriodHaulingVolumeYear({dispatch}, payload) {
            try {
               
                const result = await mesDataService.dashboard.performancePeriodHaulingVolumeYear(payload.year, payload.projectId,payload.energyId,payload.truckId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


            






        // ------------Hauling Stack Truck -----------------------------------------------------------
        async haulingStackTruckPerMonth({ dispatch }, payload) {
            try {
               
                const result = await mesDataService.dashboard.haulingStackTruckPerMonth(payload.startDate, payload.endDate, payload.projectId);
               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingStackTruckPerYear({ dispatch }, payload) {
            try {
               
                const result = await mesDataService.dashboard.haulingStackTruckPerYear(payload.year, payload.projectId);
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
            
         
        
    }


}

export { dashboard }