import { mesDataService } from "@/services/mesDataService";

const pmDash = {
    namespaced: true,
    state: {
    },

    getters: {
    },

    mutations: {

    },

    actions: {

        async pmActivityPerMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.pmActivityPerMonth(payload.startDate, payload.endDate);
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
       

    }
}

export { pmDash }
