import { mesDataService } from "@/services/mesDataService";

const energyReceive = {
    namespaced: true,

    state: {
        data: [],
    },

    getters: {
    },

    mutations: {
        LIST_DATA(state, payload) {
           payload ? state.data = payload : ''
        },
       
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        async create({ dispatch, rootState }, payload) {

            try {
                payload.updatedBy = rootState.authen.user.email
                const result = await mesDataService.energyReceive.create(payload)

                dispatch('utils/alertAdded', null, { root: true })
                                           
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email
                await mesDataService.energyReceive.update(payload.id, payload)
                              
                return
            } catch (e) {
                console.log(e)
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async list({ dispatch, commit }) {
            try {
                const result = await mesDataService.energyReceive.list();
           
                commit('LIST_DATA', result.data)
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        
        async listBetweenDate({ dispatch, commit }, payload) {
            try {
               
                const result = await mesDataService.energyReceive.listBetweenDate(payload.startDate, payload.endDate, payload.projectId);
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.energyReceive.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {                
               // dispatch('utils/handleHttpError', e, { root: true })
               dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async search({ dispatch}, payload) {
            
            try {
                const result = await mesDataService.energyReceive.search(payload);
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async getLastId({ dispatch}, energySupplyId) {
           
            
            try {
                const result = await mesDataService.energyReceive.getLastId(energySupplyId);
                                           
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async getBackDate({dispatch}, payload) {
            try {
                const result = await mesDataService.energyReceive.getBackDate(payload.days, payload.energySupplyId);
                                           
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async latestFuelReception({dispatch}, payload) {
            try {
                const result = await mesDataService.energyReceive.latestFuelReception(payload.supplyId, payload.receiveDate);
                                           
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async latestDateRemaining({ dispatch}, energySupplyId) {
           
            
            try {
                const result = await mesDataService.energyReceive.latestDateRemaining(energySupplyId);
                                           
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        



    }
}

export { energyReceive }