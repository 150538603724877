import { mesDataService } from "@/services/mesDataService";

const loadingMajor = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {},
    mutations: {
        LIST_DATA(state, payload) {
            payload.forEach(obj => {
                if (!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },

    },
    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
                const result = await mesDataService.loadingMajor.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded', null, { root: true })

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
                const result = await mesDataService.loadingMajor.update(payload.id, payload)

                commit('UPDATE_DATA', payload)               

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async get({ commit, dispatch }, payload) {
            try {
                const result = await mesDataService.loadingMajor.get(payload.workingDate, payload.projectId)

                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByPk({ dispatch }, loadingMajorId) {
            try {
                const result = await mesDataService.loadingMajor.findByPk(loadingMajorId)
               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.loadingMajor.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }, payload) {
            try {

                const result = await mesDataService.loadingMajor.list(payload.startDate, payload.endDate, payload.projectId);
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


    },
}

export { loadingMajor }