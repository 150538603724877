import { mesDataService } from "@/services/mesDataService";


const haulingMajor = {

    namespaced: true,

    state: {
        data: [],
        dialog: null,
    },

    getters: {

    },

    mutations: {
        LIST_DATA(state, payload) {
            payload.forEach(obj => {
                if (!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
        SET_DIALOG(state, payload) {
            state.dialog = payload
        }

    },

    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
                const result = await mesDataService.haulingMajor.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded', null, { root: true })

                return result.data

            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
                const result = await mesDataService.haulingMajor.update(payload.id, payload)

                commit('UPDATE_DATA', payload)               

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listBetweenDate({ dispatch, commit }, payload) {
            try {

                const result = await mesDataService.haulingMajor.listBetweenDate(payload.startDate, payload.endDate);
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async get({ commit, dispatch }, payload) {
            try {

              
                const result = await mesDataService.haulingMajor.get(payload.workingDate, payload.projectId)
              
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.haulingMajor.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async listHaulingProdDataByWorkItem({dispatch}, payload) {
            try {
                               
                const result = await mesDataService.haulingMajor.listHaulingProdDataByWorkItem(payload.startDate,payload.endDate,payload.projectId,payload.workItemId)  
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listHaulingProdData({dispatch}, payload) {
            try {
                               
                const result = await mesDataService.haulingMajor.listHaulingProdData(payload.startDate,payload.endDate,payload.projectId)  
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByPk({ dispatch }, id) {
            try {
                const result = await mesDataService.haulingMajor.findByPk(id)
               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listMeterShift({dispatch}, payload) {
            try {
                               
                const result = await mesDataService.haulingMajor.listMeterShift(payload.workingDate,payload.shiftId,payload.machineId)  
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

    }


}

export { haulingMajor }