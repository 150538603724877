import { mesDataService } from "@/services/mesDataService";


const projectTrcloud = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },

        UPDATE_DATA(state, payload) {
            
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
            
        },
    },
    actions: {

        async create({ rootState }, payload) {

            payload.updatedBy = rootState.authen.user.email
            const result = await mesDataService.projectTrcloud.create(payload)

            return result.data
        },

        async update({ rootState,commit }, payload) {

            payload.updatedBy = rootState.authen.user.email
            const result = await mesDataService.projectTrcloud.update(payload.id, payload)
            commit('UPDATE_DATA', payload)

            return result.data

        },

        async list({ commit }) {

            const result = await mesDataService.projectTrcloud.list();
            commit('LIST_DATA', result.data)

            return result.data
        },

        async destroy({ commit }, id) {

            const result = await mesDataService.projectTrcloud.destroy(id)
            commit('DEL_DATA', id)

            return result.data
        },


    }
}

export { projectTrcloud }