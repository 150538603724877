import { mesDataService } from "@/services/mesDataService";

const loading = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {},
    mutations: {
        LIST_DATA(state, payload) {
            payload.forEach(obj => {
                if (!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },

        SET_DATA(state, payload) {                               
           
            payload.forEach(obj => state.data.push(obj))
                     
        },

        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.loadingMajorId !== payload)
            
        },

        DEL_DATA_TRIP(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
            
        },

    },
    actions: {
        async create({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { ...item, updatedBy: rootState.authen.user.email }
                })
                const result = await mesDataService.loading.create(dataArray)
                commit('SET_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { 
                        ...item, 
                        updatedBy: rootState.authen.user.email 
                    }
                })
                const result = await mesDataService.loading.update(dataArray)
                
                commit('UPDATE_DATA', result.data)
                dispatch('utils/alertUpdated', null, { root: true })
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async destroy({dispatch, commit}, loadingMajorId) {
            try {
                await mesDataService.loading.destroy(loadingMajorId)
                commit('DEL_DATA', loadingMajorId)
                return 1
            } catch(e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async destroyTrip({dispatch, commit}, id) {
            try {
                await mesDataService.loading.destroyTrip(id)
                commit('DEL_DATA_TRIP', id)
                return 1
            } catch(e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },


        async list({dispatch, commit}, loadingMajorId) {
            try {
                               
                const result = await mesDataService.loading.list(loadingMajorId)
                
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async loadingTrip({dispatch}, loadingMajorId) {
            try {
                               
                const result = await mesDataService.loading.loadingTrip(loadingMajorId)  

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        
        async loadingMapHauling({dispatch}, payload) {
            try {
                               
                const result = await mesDataService.loading.loadingMapHauling(payload.startDate,payload.endDate,payload.projectId)  
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByPk({ dispatch }, id) {
            try {
                const result = await mesDataService.loading.findByPk(id)
               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByHaulingId({ dispatch }, id) {
            try {
                const result = await mesDataService.loading.findByHaulingId(id)
               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        
        




    },
}

export { loading }