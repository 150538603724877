import { mesDataService } from "@/services/mesDataService";
import { arrange } from "@/libs/common.mutations";

const truckScale = {
    namespaced: true,
    state: {
        data: []
    },
    getters: {       
        dataActived: (state) => {
            
            return state.data.filter(obj => obj.active == 1)
        }

    },
    mutations: {
        SET_DATA(state, payload) {
           arrange(state, payload)
        },
        
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        }

    },
    actions: {
        async create({dispatch, commit, rootState}, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
              
                const result = await mesDataService.truckScale.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded',null, {root: true})

                return
            } catch(e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email         

            payload.updatedBy = uid;
          
            try {
                await mesDataService.truckScale.update(payload.id, payload)
                commit('SET_DATA', payload)
                dispatch('utils/alertUpdated',null, {root: true})

                return
            } catch (e) {
                
                dispatch('utils/handleHttpError', e, { root: true })               
            } 
            
        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.truckScale.destroy(id)               
                commit('DEL_DATA', id)  
                dispatch('utils/alertSuccess',{message: "ลบข้อมูลเรียบร้อย"}, {root: true}) 
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {
           
            try {
                
                const result = await mesDataService.truckScale.list();

                commit('SET_DATA', result.data)

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

        }

    }
}

export {truckScale}