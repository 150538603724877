import { mesDataService } from "@/services/mesDataService";


const energyUsageFile = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload.forEach(obj => {
                const found = state.data.some(item => item.id === obj.id)
                if (found) {
                    const newData = state.data.map(el => el.id === obj.id ? Object.assign(el, obj) : el)
                    state.data = newData
                } else {
                    state.data.push(obj)
                }
            })
        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        async create({ dispatch, commit }, payload) {
            try {
                const result = await mesDataService.energyUsageFile.create(payload)
                commit('SET_DATA', result.data)
               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch}, payload) {
           
            try {
                const result = await mesDataService.energyUsageFile.update(payload.id, payload)
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listBetweenDate({ dispatch, commit }, payload) {
            try {
                const result = await mesDataService.energyUsageFile.listBetweenDate(payload.startDate, payload.endDate, payload.projectId)
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listByEnergySupply({ dispatch }, payload) {
            try {
                const result = await mesDataService.energyUsageFile.listByEnergySupply(payload.workingDate, payload.energySupply)
               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
    

    }
}

export { energyUsageFile }

