import { mesDataService } from "@/services/mesDataService";


const supportMachineMajorReport = {

    namespaced: true,

    state: {
        data: [],
        
    },

    getters: {

    },

    mutations: {
        LIST_DATA(state, payload) {
            payload.forEach(obj => {
                if (!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
      

    },

    actions: {
       

        async listByYearMonth({ dispatch, commit }, payload) {
            try {
               
                const result = await mesDataService.supportMachineMajor.listByYearMonth(payload.startDate, payload.endDate, payload.projectId);
                console.log(result.data)
               
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        

    }


}

export { supportMachineMajorReport }