import { mesDataService } from "@/services/mesDataService";

const workGroup = {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload.map(obj => {
                if (!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },

        SET_DATA(state, payload) {
            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },

        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        }

    },
    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;

                const result = await mesDataService.workGroup.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

             payload.updatedBy = rootState.authen.user.email         
                     
            try {
                await mesDataService.workGroup.update(payload.id, payload)
                commit('UPDATE_DATA', payload)
                dispatch('utils/alertUpdated',null, {root: true})

                return
            } catch (e) {
                
                dispatch('utils/handleHttpError', e, { root: true })               
            } 
            
        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.workGroup.destroy(id)               
                commit('DEL_DATA', id)  
                dispatch('utils/alertSuccess',{message: "ลบข้อมูลเรียบร้อย"}, {root: true}) 
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {
           
            try {
                
                const result = await mesDataService.workGroup.list();

                commit('LIST_DATA', result.data)

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

        }
    }

}

export {workGroup}