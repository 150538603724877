<template>
    <div class="loading"  transition="fade-transition">
      <span></span>
    </div>
  </template>
  
  <script>
  export default {
      name: "LoadingComponent"
  }
  </script>
  
  <style lang="scss">
  .loading {
    top: 0;
    left: 0;
    z-index: 999;    
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center;
    min-height: 100vh;
    @keyframes spin {
      to {
        transform: rotateZ(360deg);
      }
    }
  
    span {
      display: block;
      width: 60px;
      height: 60px;
      margin: 0 auto;
      border: 3px solid transparent;
      border-top-color: #fff;
      border-bottom-color: #fff;
      border-radius: 50%;
      animation: spin ease 1000ms infinite;
    }
  }
  </style>