import { mesDataService } from "@/services/mesDataService";

const logDelete = {
    namespaced: true,
    getters: {},
    mutations: {},
    actions: {
        async create({ rootState }, payload) {
         
            try {
              
                let isArray = (parameter) => Array.isArray(parameter); 
                
                if (!isArray(payload)) {
                    payload.updatedBy = rootState.authen.user.email;                   
                }                             
               await mesDataService.logDelete.create(payload)               
                
            } catch (e) {
                console.log(e)                
            }
        }
    }
}

export {logDelete}