import { mesDataService } from "@/services/mesDataService";


const report = {

    namespaced: true,
    state: {
        maxMeterData: [],
        lastMeterData: [],
        lastMajorData: [],
        avgMeterData: [],
        reportITDData: []
    },

    getters: {
    },

    mutations: {
        SET_MAX_METER(state, payload) {
            payload.map(obj => {
                if (!state.maxMeterData.some(item => item.machineId === obj.machineId)) {
                    state.maxMeterData.push(obj)
                }
            })
        },

        LIST_LAST_METER(state, payload) {
             payload ? state.lastMeterData = payload : ''

            // payload.forEach(obj => {
            //     const found = state.lastMeterData.some(item => item.machineId === obj.machineId)
            //     if (found) {
            //         const newData = state.lastMeterData.map(el => el.machineId === obj.machineId ? Object.assign(el, obj) : el)

            //         state.lastMeterData = newData
            //     } else {
            //         state.lastMeterData.push(obj)
            //     }
            // })
        },


        LIST_LAST_MAJOR(state, payload) {
              payload ? state.lastMajorData = payload : ''
            // payload.forEach(obj => {

            //     const found = state.lastMajorData.some(item => item.machineId === obj.machineId)
            //     if (found) {
            //         const newData = state.lastMajorData.map(el => el.machineId === obj.machineId ? Object.assign(el, obj) : el)
            //         state.lastMajorData = newData
            //     } else {
            //         state.lastMajorData.push(obj)
            //     }
            // })
        },

        LIST_AVG_METER_DATA(state, payload) {

            payload ? state.avgMeterData = payload : ''
        },

        LIST_PRODUCTTION_REPORT_ITD_DATA(state, payload) {

            payload ? state.reportITDData = payload : ''
        },


    },

    actions: {
        async maxMeter({ dispatch, commit }, projectId) {
            try {
                const result = await mesDataService.report.lastMileage(projectId);
                commit('SET_MAX_METER', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async lastMajorRow({ dispatch, commit }, projectId) {
            try {
                const result = await mesDataService.report.lastMajorRow(projectId);
                
                // const maxDataFn = (data) => {
                //     return new Promise((resolve) => {
                //         let arrNew = []

                //         data.forEach(obj => {
                //             if (!this[obj.machineId]) {

                //                 this[obj.machineId] = {
                //                     ...obj
                //                 }
                //                 arrNew.push(this[obj.machineId])
                //             }

                //             this[obj.machineId].workingDate = new Date(Math.max(new Date(this[obj.machineId].workingDate), new Date(obj.workingDate)))
                //             this[obj.machineId].hourMeterStart = Math.max(this[obj.machineId].hourMeterStart, obj.hourMeterStart)
                //             this[obj.machineId].hourMeterEnd = Math.max(this[obj.machineId].hourMeterEnd, obj.hourMeterEnd)
                //             this[obj.machineId].mileageStart = Math.max(this[obj.machineId].mileageStart, obj.mileageStart)
                //             this[obj.machineId].mileageEnd = Math.max(this[obj.machineId].mileageEnd, obj.mileageEnd)


                //         })

                //         resolve(arrNew)
                //     })
                // }

                // maxDataFn(result.data).then(data => {

                //     commit('LIST_LAST_MAJOR', data)
                // })

                commit('LIST_LAST_MAJOR', result.data)
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async lastMeter({ dispatch, commit }, projectId) {
            try {

                const result = await mesDataService.report.lastMeter(projectId);
                                            
                const resultUpdated = result.data.map(obj => {

                    return {
                        ...obj,
                        workingTime: new Date(obj.workingDate).getTime(),
                        workingDate: new Date(obj.workingDate).toLocaleDateString('en-CA')
                    }
                })

                // const maxDataFn = () => {
                //     return new Promise((resolve) => {
                //         const temp = []

                //         utils.groupByName(resultUpdated, 'machineId').then(groupKeyName => {

                //             Object.keys(groupKeyName).forEach(keyName => {
                //                 const arr = groupKeyName[keyName]
                //                 const maxWrkTime = Math.max(...arr.map(o => o.workingTime))
                //                 const itemObj = arr.find(o => o.workingTime === maxWrkTime)

                //                 temp.push(itemObj)
                //             })
                //         })

                //         resolve(temp)
                //     })
                // }

                // maxDataFn().then(data => {

                //     commit('LIST_LAST_METER', data)
                // })

                commit('LIST_LAST_METER', resultUpdated)

                return resultUpdated

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async listAvgMeter({ dispatch, commit }, backDays) {
            try {
                const result = await mesDataService.report.listAvgMeter(backDays);
                commit('LIST_AVG_METER_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listcoalTransportByMachineId({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.listcoalTransportByMachineId(payload.startDate, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listAvgMeterByMachineId({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.listAvgMeterByMachineId(payload.backDays, payload.machineId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async loadingTruckTrip({ dispatch }, payload) {
            try {
             
                const result = await mesDataService.report.loadingTruckTrip(payload.startDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async loadingExcavatorTrip({ dispatch }, payload) {
            try {
             
                const result = await mesDataService.report.loadingExcavatorTrip(payload.startDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async loadingsupportTrip({ dispatch }, payload) {
            try {
             
                const result = await mesDataService.report.loadingsupportTrip(payload.startDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingProduction({ dispatch }, payload) {
            try {
             
                const result = await mesDataService.report.haulingProduction(payload.startDate, payload.endDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async loadingProduction({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.loadingProduction(payload.startDate, payload.endDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingProductionTimes({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.haulingProductionTimes(payload.startDate, payload.endDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async loadingProductionTimes({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.loadingProductionTimes(payload.startDate, payload.endDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingProductionTrip({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.haulingProductionTrip(payload.startDate, payload.projectId, payload.timezone)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async supportMachineMajor({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.supportMachineMajor(payload.startDate, payload.endDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async supportMachineActivity({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.supportMachineActivity(payload.startDate, payload.endDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async dailyTruckTrip({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.dailyTruckTrip(payload.startDate, payload.endDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async energyUsageProject({ dispatch }, payload) {
            try {

                const result = await mesDataService.report.energyUsageProject(payload.startDate, payload.endDate, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async energyUsageHistory({ dispatch }, payload) {
            try {
                
                const result = await mesDataService.report.energyUsageHistory(payload.startDate, payload.endDate, payload.machineId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async listActiveAdjustedStockReport({ dispatch },payload) {
            try {
                const result = await mesDataService.report.listActiveAdjustedStockReport( payload.timeStr, payload.timeEnd)
                return result.data
            } catch (e) {              
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async listMaterialRequestReport({ dispatch }) {
            try {
                const result = await mesDataService.report.listMaterialRequestReport()
                return result.data
            } catch (e) {              
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listAvgMeterByMachineIdAndGroup({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.listAvgMeterByMachineIdAndGroup(payload.startDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listTruckMeterByMachineIdAndGroup({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.listTruckMeterByMachineIdAndGroup(payload.startDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listSupportMeterByMachineIdAndGroup({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.listSupportMeterByMachineIdAndGroup(payload.startDate, payload.projectId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async productionReportITDcreate({ dispatch, commit }, payload) {
            try {
                const result = await mesDataService.report.productionReportITDcreate(payload)

                commit('LIST_PRODUCTTION_REPORT_ITD_DATA', result.data)
                dispatch('utils/alertAdded', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByWorkingDateAndShiftId({ dispatch }, payload) {
            try {
                const result = await mesDataService.report.findByWorkingDateAndShiftId(payload.startDate, payload.shiftId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },



    }

}

export { report }