import { mesDataService } from "@/services/mesDataService";


const machineDocument = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
    },
    mutations: {  
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''

        },

        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
        UPDATE_DATA(state, payload) {
            
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
            
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        async create({ dispatch, rootState }, payload) {

            try {
                payload.updatedBy = rootState.authen.user.email
                const result = await mesDataService.machineDocument.create(payload)
              
              
                dispatch('utils/alertAdded', null, { root: true })

                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email

                await mesDataService.machineDocument.update(payload.id, payload)
                
              
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async findByMachineId({ dispatch, commit }, machineId) {
            try {
                const result = await mesDataService.machineDocument.findByMachineId(machineId);
                commit('LIST_DATA', result.data)
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({ dispatch }, id) {
            try {
                await mesDataService.machineDocument.destroy(id)
              
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },


    }
}

export { machineDocument }