import { mesDataService } from "@/services/mesDataService";
import { arrange } from "@/libs/common.mutations";


const machine = {
    namespaced: true,
    state: {
        data: [],
        dataSuite: [],
        dataByProject: [],
    },
    getters: {
        machinePmData: (state) => {
           
            return state.dataByProject.filter(obj => obj.active === 1)
        },

        // fullData: (state, getters, rootState) => {
        //     const machineTypeData = rootState.machineType.data;
        //     const brandData = rootState.brand.data;
        //     const energyData = rootState.energy.data;
        //     const meterData = rootState.meter.data;
        //     const projectData = rootState.project.data;

        //     const newArrayData = state.data.map(el => {
        //         const mctObj = machineTypeData.find(obj => obj.id === el.machineTypeId)
        //         const brandObj = brandData.find(obj => obj.id === el.brandId)
        //         const energyObj = energyData.find(obj => obj.id === el.energyId)
        //         const meterObj = meterData.find(obj => obj.id === el.meterId)
        //         const projectObj = projectData.find(obj => obj.id === el.projectId)

        //         return {
        //             ...el,
        //             ...{ machineType: mctObj.name },
        //             ...{ brand: brandObj.name },
        //             ...{ energy: energyObj.name},
        //             ...{ meter: meterObj.name},
        //             ...{ project: projectObj.name},
        //         }

        //     })

        //     return newArrayData

        // },

        dataActived: (state) => {

            return state.data.filter(obj => obj.active == 1)
        }
    },
    mutations: {
        SET_DATA(state, payload) {
            arrange(state, payload)
            
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
        LIST_DATA(state, payload) {
            payload ? state.dataSuite = payload : ''
        },
        LIST_MACHINE_BY_PROJECT(state, payload) {
            payload ? state.dataByProject = payload : []
        },
        UPDATE_MACHINE_BY_PROJECT(state, payload) {
            const newData = state.dataByProject.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.dataByProject = newData
        }
    },
    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;

                const result = await mesDataService.machine.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email
            payload.updatedBy = uid;

            try {
                await mesDataService.machine.update(payload.id, payload)
                commit('SET_DATA', payload)
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async updatePMStatus({ dispatch, commit }, payload) {
           
            try {
                await mesDataService.machine.update(payload.id, payload)
                commit('UPDATE_MACHINE_BY_PROJECT', payload)
              
                dispatch('utils/alertUpdated', null, { root: true })

                return payload
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.machine.destroy(id)               
                commit('DEL_DATA', id)   
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {

            try {
                const result = await mesDataService.machine.list();
               
                commit('SET_DATA', result.data)

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return

        },

        async machineList({ dispatch }, projectId) {

            try {
                const result = await mesDataService.machine.machineList(projectId);
               
               return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return

        },

        
        async findByDepartment({ dispatch }, id) {

            try {
                
                const result = await mesDataService.machine.findByDepartment(id);
                
                return result.data               

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
           
        },

        async listSuite({ dispatch, commit }) {
            try {
                const result = await mesDataService.machine.listSuite();
                commit('LIST_DATA', result.data)  
                                                    
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        
        async listByProjectArray({ dispatch, commit }, projectId) {
            try {
                const result = await mesDataService.machine.listByProjectArray(projectId);
                commit('LIST_MACHINE_BY_PROJECT', result.data)
                                                 
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByPk({ dispatch }, id) {
            try {
                const result = await mesDataService.machine.findByPk(id);
             
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

    },
}

export { machine }