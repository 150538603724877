import { mesDataService } from '@/services/mesDataService'

const division =  {
    namespaced: true,
    state: {
        data: []
    },
    getters: {
        fullData: (state, getters, rootState) => {
            const companyData = rootState.company.data;

            const newArrayData = state.data.map(el => {
              const dataObj = companyData.find(dep => dep.id === el.companyId)
              return {...el, ...{companyName: dataObj ? dataObj.name || null : null }}
              
            })

            return newArrayData
            
        }
        
    },
    mutations: {
        SET_DATA(state, payload) {
            const arr = Array.isArray(payload)
            if (arr) {
                // list 
                state.data = payload
            } else {
                // update                
                const newData= state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el )
                state.data = newData

                //add 
                if (!state.data.some(obj => obj.id === payload.id)) {
                    state.data.splice(0, 0, payload)
                }
            }

        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        }
    },
    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;

               const result = await mesDataService.division.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded',null, {root: true})

                return
               
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email         

            payload.updatedBy = uid;

          
            try {
                await mesDataService.division.update(payload.id, payload)
                commit('SET_DATA', payload)
                dispatch('utils/alertUpdated',null,{root:true})

                return
             
            } catch (e) {
                
                dispatch('utils/handleHttpError', e, { root: true })
               
            } 
            
        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.division.destroy(id)               
                commit('DEL_DATA', id)   
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {
           
            try {
                
                const result = await mesDataService.division.list();

                commit('SET_DATA', result.data)
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return

        }


    }

}

export {division}