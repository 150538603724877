// import { mesDataService } from "@/services/mesDataService";
// import { arrange } from "@/libs/common.mutations";

const pmPrint = {
    namespaced: true,
    state: {
        dataPrint: [],
        dataPrintMR: [],

    },
    getters: {

    },
    mutations: {
        SET_DATA(state, payload) {
            state.dataPrint = payload;
        },
        SET_DATAMR(state, payload) {
            state.dataPrintMR = payload;
        },
        DEL_DATA(state) {
            state.dataPrint = [];
        }
    },
    actions: {
        async addDataPrint({ commit, dispatch }, payload) {
            try {
                const item = payload;
                commit('SET_DATA', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
        async addDataMR({ commit, dispatch }, payload) {
            try {
                const item = payload;
                commit('SET_DATAMR', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
        async destroy({ commit, dispatch }) {
            try {
                commit('DEL_DATA');
                return 1;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
                return 0;
            }
        },

    },
};

export { pmPrint };
