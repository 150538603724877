import { mesDataService } from "@/services/mesDataService";

const energyDashboard = {
    namespaced: true,
    state: {
    },

    getters: {
    },

    mutations: {

    },

    actions: {
        async energySeviceCarMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.energySeviceCarMonth(payload.startDate, payload.endDate);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async energySeviceCarYear({ dispatch }, year) {
            try {
                const result = await mesDataService.dashboard.energySeviceCarYear(year);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async serviceCarPerform({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.serviceCarPerform(
                    payload.startDate, 
                    payload.endDate, 
                    payload.machineTypeId, 
                    payload.projectId
                )

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async energyConsumingMachineGroup({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.energyConsumingMachineGroup(payload.startDate, payload.endDate, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingWeight({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.haulingWeight(payload.startDate, payload.endDate);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingEnergy({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.haulingEnergy(payload.startDate, payload.endDate);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async fixedTank({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.fixedTank(payload);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async projectEnergy({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.projectEnergy(payload.startDate, payload.endDate);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async dieselRemaining({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.dieselRemaining(payload);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async dieselPerMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.dieselPerMonth(payload.startDate, payload.endDate, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async dieselPerYear({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.dieselPerYear(payload.year, payload.projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


    }
}

export { energyDashboard }

