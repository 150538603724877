import { mesDataService } from "@/services/mesDataService";


const hauling = {

    namespaced: true,

    state: {
        data: [],
    },

    getters: {   
        
    },

    mutations: {
        LIST_DATA(state, payload) {
            payload.map(obj => {
                if(!state.data.some(item => item.id === obj.id)) {
                    state.data.push(obj)
                }
            })
        },

        UPDATE_DATA(state, payload) {
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },

        SET_DATA(state, payload) {                               
           
            payload.forEach(obj => state.data.push(obj))
                     
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.haulingMajorId !== payload)
        },
        DEL_DATA_TRIP(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
            
        },
    },

    actions: {
        async create({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { ...item, updatedBy: rootState.authen.user.email }
                })
              
                const result = await mesDataService.hauling.create(dataArray)
                commit('SET_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { 
                        ...item, 
                        updatedBy: rootState.authen.user.email 
                    }
                })
                const result = await mesDataService.hauling.update(dataArray)
                
                commit('UPDATE_DATA', result.data)
                dispatch('utils/alertUpdated', null, { root: true })
                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({dispatch, commit}, haulingMajorId) {
            try {
                await mesDataService.hauling.destroy(haulingMajorId)
                commit('DEL_DATA', haulingMajorId)
                return 1
            } catch(e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async destroyTrip({dispatch, commit}, id) {
            try {
                await mesDataService.hauling.destroyTrip(id)
                commit('DEL_DATA_TRIP', id)
                return 1
            } catch(e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },


        async list({dispatch, commit}, haulingMajorId) {
            try {
                
                const result = await mesDataService.hauling.list(haulingMajorId)
                
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingLoadingSpot({dispatch, commit}, haulingMajorId) {
            try {
                
                const result = await mesDataService.hauling.haulingLoadingSpot(haulingMajorId)
                
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingTrip({dispatch}, haulingMajorId) {
            try {
                               
                const result = await mesDataService.hauling.haulingTrip(haulingMajorId)  

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        
        async haulingTripUsed({dispatch}, haulingMajorId) {
            try {
                               
                const result = await mesDataService.hauling.haulingTripUsed(haulingMajorId)  

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async haulingGroupExcavator({dispatch}, payload) {
            try {
                               
                const result = await mesDataService.hauling.haulingGroupExcavator(payload.startDate,payload.endDate,payload.projectId)  
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        

        async haulingDayForLoading({dispatch}, payload) {
            try {
                               
                const result = await mesDataService.hauling.haulingDayForLoading(payload.workingDate,payload.projectId,payload.shiftId,payload.machineId,payload.workItemId)  
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        

    }


}

export { hauling }