import { mesDataService } from '@/services/mesDataService'

const company =  {
    namespaced: true,
    state: {
        data: []
    },
    getters: {         
    },
    mutations: {
        SET_DATA(state, payload) {
            const arr = Array.isArray(payload)
            if (arr) {
                // list 
                state.data = payload
            } else {
                // update                
                const newData= state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el )
                state.data = newData

                //add 
                if (!state.data.some(obj => obj.id === payload.id)) {
                    state.data.splice(0, 0, payload)
                }
            }

        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        }
    },
    actions: {

        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;

               const result = await mesDataService.company.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded',null, {root: true})
            } catch (e) {
               
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email         

            payload.updatedBy = uid;
          
            try {
                await mesDataService.company.update(payload.id, payload)
                commit('SET_DATA', payload)
                dispatch('utils/alertUpdated',null,{root:true})
            } catch (e) {
                
                dispatch('utils/handleHttpError', e, { root: true })
               
            }
            
        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.company.destroy(id)               
                commit('DEL_DATA', id)   
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {
        
            try {                
                const result = await mesDataService.company.list();

                commit('SET_DATA', result.data)
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return

        }


    }

}

export {company}