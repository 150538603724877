import { mesDataService } from "@/services/mesDataService";


const bookmark = {
    namespaced: true,
    state: {
        data: null
    },
    getters: {
    },
    mutations: {
        SET_DATA(state, payload) {
            state.data = payload

        }
    },
    actions: {
        async create({ dispatch, rootState }, payload) {

            try {
                payload.email = rootState.authen.user.email
                const result = await mesDataService.bookmark.create(payload)
               
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState }, payload) {

            try {
                payload.email = rootState.authen.user.email
                const result = await mesDataService.bookmark.update(payload.email, payload)
             
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async get({ dispatch, commit }, payload) {
            try {
                
                if (payload) {
                    const result = await mesDataService.bookmark.get(payload)
                  
                    if (result.data) {
                        commit('SET_DATA', result.data)
                    }

                    return result.data
                }



            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        }


    }

}


export { bookmark }