import { mesDataService } from "@/services/mesDataService";

const energyUsage = {
    namespaced: true,
    state: {
        data: [],
        statusData: [],
    },
    getters: {
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload ? state.data = payload : []

        },
        LIST_STATUS(state, payload) {
            payload ? state.statusData = payload : []

        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },
        UPDATE_DATA(state, payload) {

            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {

        async create({ commit }, payload) {

            const result = await mesDataService.energyUsage.create(payload)
            result.data.workItemName = payload.workItemName
            result.data.firstName = payload.firstName
            result.data.lastName = payload.lastName
            result.data.updatedByFirstName = payload.updatedByFirstName
            result.data.updatedByNickName = payload.updatedByNickName
            result.data.energySupplyName = payload.energySupplyName
           
            commit('SET_DATA', result.data)

            return result.data
        },

        async update({ commit }, payload) {

            const result = await mesDataService.energyUsage.update(payload.id, payload)
            commit('UPDATE_DATA', payload)

            return result.data
        },

        updateField(_, payload) {
            
            return new Promise((resolve, reject) => {
                try {
                    const apiFn = async () => {
                        const result = await mesDataService.energyUsage.update(payload.id, payload)

                        return result.data
                    }
                    
                    const result = apiFn()

                    resolve(result)
                } catch (e) {
                    reject(e)
                }

            })

        },



        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.energyUsage.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async listBetweenDate({ dispatch, commit }, payload) {
            try {

                const result = await mesDataService.energyUsage.listBetweenDate(payload.startDate, payload.endDate, payload.projectId);

                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listByMachineId({ dispatch }, machineId) {
            try {

                const result = await mesDataService.energyUsage.listByMachineId(machineId)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async getMaxMrno(_, payload) {

            const result = await mesDataService.energyUsage.getMaxMrno(payload.startDate, payload.endDate);


            return result.data

        },

        async listStatus({commit}, payload) {

            const result = await mesDataService.energyUsage.listStatus(payload.startDate, payload.endDate, payload.projectId);
            commit('LIST_STATUS', result.data)

            return result.data
        },



    }
}

export { energyUsage }