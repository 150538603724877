import { mesDataService } from "@/services/mesDataService";

const distanceMatrix = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
        },
        
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        async create({ dispatch, rootState }, payload) {

            try {                
               
                payload.updatedBy = rootState.authen.user.email

                const result = await mesDataService.distanceMatrix.create(payload)
                             
                dispatch('utils/alertAdded', null, { root: true })

                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

       
        async update({ dispatch, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email

                await mesDataService.distanceMatrix.update(payload.id, payload)
              
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },
        

        async list({ dispatch, commit }, projectId) {
            
            try {
                const result = await mesDataService.distanceMatrix.list(projectId);

                commit('LIST_DATA', result.data)                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },      


        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.distanceMatrix.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },


    }
}

export { distanceMatrix }