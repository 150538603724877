const reportITD = {
    namespaced: true,
    state: {
        reportNameITD: null,
        dataPrintOilUse: [],
        dataPrintCoaltransport: [],
        dataPrintMining: [],
        dataproductionReport: [],

   
    },
    getters: {},
    mutations: {
        SET_REPORT_NAME(state,payload) {
            state.reportNameITD = payload;        
        },
        SET_DATAITDOIL(state, payload) {
            state.dataPrintOilUse = payload;
        },
        SET_DATAICOAL(state, payload) {
            state.dataPrintCoaltransport = payload;
        },
        SET_DATAMINING(state, payload) {
            state.dataPrintMining = payload;
        },
        SET_DATA_PRODUCTION_REPORT(state, payload) {
            state.dataproductionReport = payload;
        },
    },
    actions: {
        async addDataOilUse({ commit, dispatch }, payload) {
            try {
                const item = payload;
                commit('SET_DATAITDOIL', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
        async addCoaltransport({ commit, dispatch }, payload) {
            try {
                const item = payload;
                
                commit('SET_DATAICOAL', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
        async addDataMining({ commit, dispatch }, payload) {
            try {
                const item = payload;
                commit('SET_DATAMINING', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
        async addproductionReport({ commit, dispatch }, payload) {
            try {
                const item = payload;
                console.log(item);
                commit('SET_DATA_PRODUCTION_REPORT', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
            
    }
}

export { reportITD }