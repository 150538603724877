import { mesDataService } from "@/services/mesDataService";

const pmAppointment = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {

    },
    actions: {
        async create({ dispatch, rootState }, payload) {

            try {
                payload.updatedBy = rootState.authen.user.email
                const result = await mesDataService.pmAppointment.create(payload)

                dispatch('utils/alertAdded', null, { root: true })

                return result.data

            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email

                await mesDataService.pmAppointment.update(payload.id, payload)

             //   dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
               
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async listActive({ dispatch }) {
            try {

                const result = await mesDataService.pmAppointment.listActive()

                return result.data
            } catch (e) {
              
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

       
       
        async listActiveNotApproved({ dispatch }) {
            try {

                const result = await mesDataService.pmAppointment.listActiveNotApproved()

                return result.data
            } catch (e) {              
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listApproved({ dispatch }) {
            try {

                const result = await mesDataService.pmAppointment.listApproved()

                return result.data
            } catch (e) {              
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({ dispatch }, id) {
            try {
                await mesDataService.pmAppointment.destroy(id)

                return 1
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },
    }
}


export { pmAppointment }

