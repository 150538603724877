import { mesDataService } from "@/services/mesDataService";

const machineDailyReport = {

    namespaced: true,

    state: {
        data: [],        
    },

    getters: {
    },

    mutations: {
        LIST_DATA(state, payload) {
            state.data = payload
        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.id === payload.id)) {
                state.data.splice(0, 0, payload)
            }
        },     

    },

    actions: {       

        async listByYearMonth({ dispatch, commit }, payload) {
            try {
               
                const result = await mesDataService.machineDaily.listByYearMonth(payload.year, payload.month, payload.projectId);
               
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        

    }


}

export { machineDailyReport }