import { mesDataService } from "@/services/mesDataService";

const projectRoles = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {},
    mutations: {
        LIST_DATA(state, payload) {
            state.data = payload
        },

        SET_DATA(state, payload) {                               
           
            payload.forEach(obj => state.data.push(obj))
                     
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.projectId !== payload)
        }
    },
    actions: {
        async create({ dispatch, rootState, commit }, payload) {
            try {
                const dataArray = payload.map(item => {
                    return { ...item, updatedBy: rootState.authen.user.email }
                })
                const result = await mesDataService.projectRoles.create(dataArray)
                commit('SET_DATA', result.data)

                dispatch('utils/alertAdded', null, { root: true })

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({ dispatch, commit }, memberId) {           
            try {
                await mesDataService.projectRoles.destroy(memberId)
                commit('DEL_DATA', memberId)
                return 1
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async findByMemberId({ dispatch }, memberId) {
            try {
                const result = await mesDataService.projectRoles.findByMemberId(memberId);
                
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

        },

        

       



    }
}

export {projectRoles}