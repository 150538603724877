const utils = {
    namespaced: true,
    state: {
        alertToggle: null,
        alertProp: { type: '', message: '' },      
        systemBarData: [],
        systemBarSelected: [],
        dailyTab: null,
        loading: null,
        newMachineModal: null,
        maintenanceTab: null,
        maintenanceSettingTab: null,
        maintenanceStockTab: null,
        maintenanceReportTab: null,
    },
    getters: {},
    mutations: {
        SET_ALERT_TOGGLE(state) {
            state.alertToggle = !state.alertToggle;

        },
        SET_ALERT_PROP(state, payload) {
            state.alertProp = Object.assign(payload)

        },        
        SET_DAILY_TAB(state, payload) {
            state.dailyTab = payload
        },
        SET_LOADING(state, payload) {
            state.loading = payload
        },
        TOGGLE_NEW_MACHINE(state) {
            state.newMachineModal = !state.newMachineModal
        },
        SET_SYSTEM_BAR(state, payload) {
            payload ? state.systemBarData = payload : []
        },
        SET_SYSTEM_BAR_SELECTED(state, payload) {
            payload ? state.systemBarSelected = payload : []
        },
        SET_MAINTENANCE_TAB(state, payload) {
            state.maintenanceTab = payload
        },
        SET_MAINTENANCE_SETTING_TAB(state, payload) {
            state.maintenanceSettingTab = payload
        },
        SET_MAINTENANCE_STOCK(state, payload) {
            state.maintenanceStockTab = payload
        },
        SET_MAINTENANCE_REPORT_TAB(state, payload) {
            state.maintenanceReportTab = payload
        }




    },
    actions: {
        alertError({ commit }, error) {
            const alertProp = {
                type: 'error',
                message: `${error.message}`
            }

            commit('SET_ALERT_TOGGLE', null)
            commit('SET_ALERT_PROP', alertProp)
        },

        alertWarning({ commit }, error) {
            const alertProp = {
                type: 'warning',
                message: `${error.message}`
            }

            commit('SET_ALERT_TOGGLE', null)
            commit('SET_ALERT_PROP', alertProp)
        },

        alertSuccess({ commit }, txtObj) {
            const alertProp = {
                type: 'success',
                message: `${txtObj.message}`
            }

            commit('SET_ALERT_TOGGLE', null)
            commit('SET_ALERT_PROP', alertProp)
        },

        alertUpdated({ commit }) {
            const alertProp = {
                type: 'success',
                message: 'แก้ไขข้อมูลเรียบร้อย'
            }

            commit('SET_ALERT_TOGGLE', null)
            commit('SET_ALERT_PROP', alertProp)
        },

        alertAdded({ commit }) {
            const alertProp = {
                type: 'info',
                message: 'บันทึกข้อมูลเรียบร้อย'
            }

            commit('SET_ALERT_TOGGLE', null)
            commit('SET_ALERT_PROP', alertProp)
        },

        handleHttpError({ dispatch }, error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 401) {
                    // Handle 401 Unauthorized error

                    dispatch('alertError', { message: "Your token has expired." })
                    // You can also redirect to a login page or show a message to the user
                   
                    dispatch('authen/signout', null, { root: true })
                }
                else {
                    dispatch('alertError', { message: error.response.data.message });
                }
            } else if (error.request) {
                // The request was made but no response was received              
                dispatch('alertError', { message: `'No response received:'${error.request.response}` })
            } else {
                // Something happened in setting up the request that triggered an Error  
                if(error.message){   
                dispatch('alertError', { message: error.message })
                }else{
                dispatch('alertError', { message: error })
                }
            }
            // Optionally, you can throw the error again to be handled by the caller
            throw error;
        }


    }
}

export { utils }