import { mesDataService } from "@/services/mesDataService";
import { arrange } from "@/libs/common.mutations";

const project = {
    namespaced: true,
    state: {
        data: []
    },
    getters: {
        fullData: (state, getters, rootState) => {
            const departmentData = rootState.department.data;
            const divisionData = rootState.division.data;
            const companyData = rootState.company.data;

            const projectWorkData = rootState.projectWork.data;
            const workSheetData = rootState.workSheet.data;

            const newData = state.data.map(item => {

                const departmentObj = departmentData.find(dep => dep.id === item.departmentId)
                const divisionObj = divisionData.find(obj => obj.id === departmentObj.divisionId)
                const companyObj = companyData.find(obj => obj.id === divisionObj.companyId)


                const projectWorkDataUpdated = projectWorkData.map(obj => {
                    const wsObj = workSheetData.find(el => el.id === obj.workSheetId)

                    return { ...obj, name: wsObj.name }
                })


                const mergeName = [];
                projectWorkDataUpdated.forEach(obj => obj.projectId === item.id ? mergeName.push(obj.name) : null)


                return {
                    ...item,
                    departmentName: departmentObj ? departmentObj.name || null : null,
                    divisionId: divisionObj ? divisionObj.id || null : null,
                    divisionName: divisionObj ? divisionObj.name || null : null,
                    companyId: companyObj ? companyObj.id || null : null,
                    companyName: companyObj ? companyObj.name || null : null,
                    workSheet: mergeName.toString()
                }

            })



            return newData

        },
        dataActived: (state) => {

            return state.data.filter(obj => obj.active == 1)
        }
    },
    mutations: {
        SET_DATA(state, payload) {
            arrange(state, payload)
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        }

    },
    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;

                const result = await mesDataService.project.create(payload)

                commit('SET_DATA', result.data)
                dispatch('utils/alertAdded', null, { root: true })

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email

            payload.updatedBy = uid;

            try {
                await mesDataService.project.update(payload.id, payload)
                commit('SET_DATA', payload)

                return
            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })

            }

        },

        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.project.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {
              
               dispatch('utils/handleHttpError', e, { root: true })
               return 0
            }
        },

        async list({ dispatch, commit }) {

            try {

                const result = await mesDataService.project.list();

                commit('SET_DATA', result.data)
                return result.data
            } catch (e) {            
              dispatch('utils/handleHttpError', e, { root: true })
            }

            return
        },

        async findByDepartmentId({ dispatch }, departmentId) {
            try {

                const result = await mesDataService.project.findByDepartmentId(departmentId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listAll({ dispatch }) {
            try {

                const result = await mesDataService.project.listAll();

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByPk({ dispatch }, projectId) {
            try {

                const result = await mesDataService.project.findByPk(projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


    }



}

export { project }