import { mesDataService } from '@/services/mesDataService'
import { arrange } from "@/libs/common.mutations";

const member = {
    namespaced: true,
    state: {
        data: [],
        dialog: null,
    },
    getters: {
        dataActived: (state) => {

            return state.data.filter(obj => obj.active == 1)
        }
    },
    mutations: {
        SET_DATA(state, payload) {
            arrange(state, payload)
        },

        SET_DIALOG(state) {
            state.dialog = !state.dialog
        },

        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },

    actions: {

        async get({ dispatch }, id) {
            try {

                if (!id) throw ("ID not found")

                const result = await mesDataService.member.get(id)

                return result
            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async getByEmail(_, email) {
            
            const result = await mesDataService.member.getByEmail(email)
            return result.data
        },

        async create({ commit }, payload) {
          
            const result = await mesDataService.member.create(payload)

            commit('SET_DATA', result.data)
        },


        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email

            payload.updatedBy = uid;

            try {
                await mesDataService.member.update(payload.id, payload)
                commit('SET_DATA', payload)
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async list({ dispatch, commit }) {

            try {
                const result = await mesDataService.member.list();
                commit('SET_DATA', result.data)

            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })
            }

        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.member.destroy(id)               
                commit('DEL_DATA', id)   
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

    }
}

export { member }