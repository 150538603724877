<template>
    <div class="alert-content" style="display: block;">
        <transition-group name="slide" mode="out-in">
            <v-alert style=" width: 420px;border-radius: 15px;opacity: 0.95;padding: 10px;"
                v-for="(alert, index) in alerts" :key="index" :value="alert.show" :class="alert.type"
                class="slide-alert" :close-text="alert.closeText"  @input="alert.show = false" elevation="3"
                transition="scale-transition">
                <span class="time-stamp">
                    <v-icon style="font-size: small;  color: #fff;">mdi-clock-time-eight-outline</v-icon>
                    {{ alert.dateStamp }}</span>
                <v-row no-gutters>
                    <v-col cols="12" sm="2" md="2">
                        <img v-if="alert.type === 'success'" src="IconImg/check.png" class="icon-img"
                            alt="Success icon">
                        <img v-if="alert.type === 'error'" src="IconImg/info.png" class="icon-img" alt="Error icon">
                        <img v-if="alert.type === 'warning'" src="IconImg/caution.png" class="icon-img"
                            alt="Error icon">
                        <img v-if="alert.type === 'info'" src="IconImg/verified.png" class="icon-img" alt="Error icon">
                    </v-col>
                    <v-col cols="12" sm="9" md="9" class="text-message">
                      <div v-html="alert.message"></div>  
                    </v-col>
                    <v-col  cols="12" sm="1" md="1" class="shrink">
                        <v-icon @click="closeAlertPopArray(index)" color="white">mdi-close-circle-outline</v-icon>
                    </v-col>

                </v-row>
            </v-alert>
        </transition-group>
    
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
    data() {
        return {
            alerts: [],//[{ "show": true, "type": "warning", "message": "แก้ไขข้อมูลเรียบร้อย", "closeText": "Close", "dateStamp": this.formatDate(new Date()), }],
            types: null,
            message: "",
        };
    },
    methods: {
        ...mapMutations("utils", ["SET_ALERT_TOGGLE"]),
        closeAlertPopArray(index) {

          //  this.alerts[index].show = false;
            const lastIndex = this.alerts.findLastIndex(ele => ele)
            const dataIndex = this.alerts.findIndex((element) => element.show === true)

            setTimeout(() => {
                if (lastIndex === index) {
                    this.alerts.splice(index, 1);
                }
                if (dataIndex < 0) {
                    this.alerts = []
                }
            }, 500);

        },
        closeAlert() {
            if (this.alertToggle === true) {
                this.SET_ALERT_TOGGLE();
            }
        },
        formatDate(date) {
            let dd = String(date.getDate()).padStart(2, '0');
            let mm = String(date.getMonth() + 1).padStart(2, '0'); 
            let yy = String(date.getFullYear()).slice(-2);
            let HH = String(date.getHours()).padStart(2, '0');
            let ii = String(date.getMinutes()).padStart(2, '0');
            let ss = String(date.getSeconds()).padStart(2, '0');
            return `${dd}/${mm}/${yy} ${HH}:${ii}:${ss}`;
        },
    },
    computed: {
        ...mapState("utils", ["alertToggle", "alertProp"]),
    },
    watch: {
        alertToggle() {
            if (this.alertToggle === true) {
                this.alerts.push({
                    show: this.alertToggle,
                    type: this.alertProp.type,
                    message: this.alertProp.message,
                    closeText: 'Close',
                    dateStamp: this.formatDate(new Date()),
                });
                

                setTimeout(() => {
                    this.closeAlert();
                   // const dataIndex = this.alerts.findIndex((element) => element.show === true)
                    // console.log('--->',dataIndex)
                   // this.closeAlertPopArray(dataIndex)
                   this.alerts = []
                }, 5000);
            }

        },
    },

}
</script>

<style scoped>
.alert-content {
    top: 7%;
    right: 20px;
    z-index: 999 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.success {
    background: linear-gradient(90deg, rgba(156, 220, 120, 1) 9%, rgba(85, 209, 121, 1) 65%);
}

.error {
    background: linear-gradient(90deg, rgba(255, 171, 158, 1) 9%, rgba(255, 92, 92, 1) 55%);
}

.warning {
    background: linear-gradient(90deg, rgb(255, 231, 158) 9%, rgb(255, 171, 92) 55%);
}

.info {
    background: linear-gradient(90deg, rgb(158, 237, 255) 9%, rgb(92, 182, 255) 55%);
}

.icon-img {
    height: 48px;
    margin-right: 8px;
}

.text-message {
    align-content: center;
    text-shadow: 0 0 #d6d90098;
}

.time-stamp {
    color: #ffffffc2;   
    font-size: x-small;
    position: absolute;
    right: 18px;
    bottom: 3px;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.4s;
    transition-duration: 0.5s; 
}

.slide-enter,
.slide-leave-to {
    transform: translateY(-108%);
}

</style>