import { mesDataService } from "@/services/mesDataService";

const pmActualRound = {
    namespaced: true,
    state: {
        data: [],
        datalistMaxIdNotClosingWork: [],
        listcheckPlanIsNotFound:[]

    },
    getters: {
    },
    mutations: {       
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
        },
        LIST_DATA_CHECK_CLOSINGWORK(state, payload) {
            payload ? state.datalistMaxIdNotClosingWork = payload : ''
        },
        LIST_DATA_CHECK_PlAN_IS_NOT_FOUND(state, payload) {
            payload ? state.listcheckPlanIsNotFound = payload : ''
        },
        DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
              
        async listMaxId({ dispatch, commit }) {
            try {
                const result = await mesDataService.pmActualRound.listMaxId();

                commit('LIST_DATA', result.data)                           
                                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async listMaxIdNotClosingWork({ dispatch, commit }) {
            try {
                const result = await mesDataService.pmActualRound.listMaxIdNotClosingWork();

                commit('LIST_DATA_CHECK_CLOSINGWORK', result.data)                           
                                
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async create({ dispatch, rootState }, payload) {

            try {
                payload.updatedBy = rootState.authen.user.email
                const result = await mesDataService.pmActualRound.create(payload) 
                dispatch('listMaxId')       
                dispatch('utils/alertAdded', null, { root: true })
                        

                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email

                await mesDataService.pmActualRound.update(payload.id, payload)
                dispatch('listMaxId')       
                
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async destroy({ dispatch, commit }, payload) {
            try {
                await mesDataService.pmActualRound.destroy(payload.id,payload.roundNumber)
                commit('DEL_DATA', payload.id)
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async destroyId({ dispatch, commit }, payload) {
            try {
                await mesDataService.pmActualRound.destroyId(payload)
                commit('DEL_DATA', payload.id)
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },
        async listcheckPlanIsNotFound({ dispatch,commit }) {
            try {
                const result = await mesDataService.pmActualRound.checkPlanIsNotFound();
                commit('LIST_DATA_CHECK_PlAN_IS_NOT_FOUND', result.data)
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async historyRounds({ dispatch },payload) {
            try {
                const result = await mesDataService.pmActualRound.historyRounds(payload);
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


    },

    
}


export { pmActualRound }