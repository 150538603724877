import { mesDataService } from "@/services/mesDataService";


const pmTableItem = {
    namespaced: true,
    state: {        
    },
    getters: {
    },
    mutations: {
       
    },
    actions: {
        async create({rootState }, payload) {
           
                payload.updatedBy = rootState.authen.user.email                
                const result = await mesDataService.pmTableItem.create(payload)             
              
                return result.data
        },

        async update({rootState }, payload) {
          
                payload.updatedBy = rootState.authen.user.email
                await mesDataService.pmTableItem.update(payload.id, payload)
             
                return
        },

       
        async destroy({ dispatch }, id) {
            try {
                await mesDataService.pmTableItem.destroy(id)
               
                return 1
            } catch (e) {                
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async listByTableId({ dispatch }, id) {
            try {
                const result = await mesDataService.pmTableItem.listByTableId(id);
                         
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async listGroupPartId({ dispatch }) {
            try {
                const result = await mesDataService.pmTableItem.listGroupPartId();
                         
              
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

       


    }
}

export { pmTableItem }